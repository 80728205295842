import React, {Component} from 'react';
import * as yup from 'yup';
import {Formik, FormikValues} from 'formik';
import {Alert, Card, Col, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../../common/FeedbackButton';
import Footer from '../../layout/Footer';
import {connect} from 'react-redux';
import Api from '../../../api';
import UploadButton from '../../common/UploadButton';
import {FormikCheckbox} from '../../common/formik/FormikCheckbox';
import {history} from "../../../state/configureStore";
import styles from "../../form.module.scss";
import {memoize} from "../../../form/fieldValidation";
import debounce from "debounce-promise";

const api = new Api();

const getInitialValues = (venueId) => ({
    backendName: '',
    excludeInvalidLines: false,
    name: '',
    venue: {id: venueId},
});

const FORM_SCHEMA = yup.object().shape({
    backendName: yup.string().required(),
    name: yup.string().required(),
    excludeInvalidLines: yup.bool(),
});

const getVenuePlanByBackendNameDebounced = debounce(
    (backendName, venueId) => api.getVenuePlanByBackendNameAndVenueId(backendName, venueId), 500
);

class Form extends Component {

    constructor(props) {
        super(props);

        this.state = {
            importFile: {},
            createViaImport: false,
            validationPending: false,
            importPending: false,
            excludeInvalidLines: false,
            validFile: null,
            faultyRows: [],
            missingPlacePools: [],
            errorMessage: ''
        }
    }

    validateCSVImportFile = (file) => {
        this.setState({
            validationPending: true,
            createViaImport: true,
            faultyRows: [],
            missingPlacePools: [],
            importFile: {},
            validFile: null
        });

        api.validateCSVImport(file, this.props.venueId).then(res => {
            this.setState({importFile: file, validationPending: false, validFile: true});
        }).catch(errResponse => {
            if (errResponse.hasOwnProperty('faultyRows')) {
                this.setState({
                    faultyRows: errResponse.faultyRows,
                    missingPlacePools: errResponse.missingPlacePools,
                    validationPending: false,
                    validFile: false,
                    errorMessage: errResponse.message,
                    importFile: file
                });
            } else {
                this.setState({validationPending: false, validFile: false, errorMessage: errResponse.message});
            }

        });
    };

    createVenuePlanViaImport = (file, venuePlan) => {
        this.setState({importPending: true});
        api.createVenuePlanViaCSVImport(file, venuePlan).then(res => {
            if (res.hasOwnProperty('id')) {
                history.push(`/base/venue/${res.venue.id}/venue-plan/${res.id}`)
            }
        }).catch(errResponse => {
            this.setState({importPending: false});
            // TODO once I learn about the GlobalErrors and how to use them, respond to errors
        });
    };

    handleSubmit = (eventObject, venueId, formik) => {
        if (this.state.createViaImport
            && formik.values.backendName
            && formik.values.name
            && (formik.values.excludeInvalidLines === 'true' || this.state.validFile)
        ) {
            this.createVenuePlanViaImport(this.state.importFile, JSON.stringify({
                backendName: formik.values.backendName,
                name: formik.values.name,
                excludeInvalidLines: formik.values.excludeInvalidLines,
                venue: {
                    id: venueId
                }
            }));
        } else {
            formik.handleSubmit(eventObject);
        }
    }

    render() {
        let {onSubmit, submitPending, venuePlan, venueId, helpTextsVisible} = this.props;

        const validateBackendName = memoize({
            initialValue: venuePlan.backendName,
            async validate(backendName) {
                if (!backendName) {
                    return;
                }

                const responseVenuePlan = await getVenuePlanByBackendNameDebounced(backendName, venueId);
                if (responseVenuePlan && venuePlan.id !== responseVenuePlan.id) {
                    return 'Titel des Saalplans bereits vergeben';
                }
            }
        });

        return <Formik initialValues={{...getInitialValues(venueId), ...venuePlan}}
                       validationSchema={FORM_SCHEMA}
                       onSubmit={onSubmit}
        >
            {formik => {
                return (
                    <form className={styles.formCotainer} onSubmit={(event) => {
                        event.preventDefault();
                        this.handleSubmit(event, venueId, formik);
                    }}>
                        <div className={styles.formBox}>
                            <h2 className="font-weight-bold pb-3 mb-3">Allgemein</h2>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        label="Titel des Saalplans*"
                                        name="backendName"
                                        testid="backendName"
                                        helpTextsVisible={helpTextsVisible}
                                        validate={validateBackendName}
                                        helpText="Dieser Name wird nur im Backend benutzt."
                                    />
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        label="Öffentliche Bezeichnung des Saalplans*"
                                        helpTextsVisible={helpTextsVisible}
                                        name="name"
                                        testid="name"
                                    />
                                </Col>
                                <Col className="col-md-4 align-items-center d-flex">
                                    <FeedbackButton
                                        target='_blank'
                                        title="im Saalplaneditor bearbeiten"
                                        to={`/base/venue-plan/${venuePlan.id}/seating/editor/ui`}
                                    >
                                        im Saalplaneditor bearbeiten
                                    </FeedbackButton>
                                </Col>
                            </Row>

                            {!('id' in venuePlan) && <Row>
                                <Col className="col-md-4">
                                    <FormikCheckbox label="Entferne die ungültigen Zeilen aus der Import-Datei"
                                                    name="excludeInvalidLines"/>
                                </Col>
                            </Row>
                            }
                            <Row>
                                {!('id' in venuePlan) &&
                                <Col className="col-md-6">
                                    <UploadButton acceptedTypes={['.csv']}
                                                    validFile={this.state.validFile}
                                                    validationPending={this.state.validationPending}
                                                    label={'CSV-Datei für Import auswählen'}
                                                    onChange={this.validateCSVImportFile}>
                                    </UploadButton>
                                    <br/>
                                    <div className={!this.props.helpTextsVisible ? 'hidden' : 'text-muted'}>
                                        <span>Achten Sie auf Folgendes:</span>
                                        <ul>
                                            <li>Die Reihenfolge der Spalten, korrektes Trennzeichen(,), UTF8.</li>
                                            <li>Vorhandene Spaltenköpfe mit korrekten Inhalten (werden für die Validierung verwendet).</li>
                                            <li>
                                                Alle Spalten müssen mit Daten gefüllt sein.
                                                <ul>
                                                    <li>Alle Zeilen müssen in den Spalten, exklusive der Platzpool-Spalten, Daten enthalten.</li>
                                                    <li>Alle Zeilen in den Platzpool-Spalten müssen mindestens eine Platzpoolzuweisung besitzen.</li>
                                                    <li>Nach den Platzpool-Spalten dürfen keine weiteren Spalten folgen, da diese sonst als Platzpools betrachtet werden.</li>
                                                </ul>
                                            </li>
                                            <li>Keine Spalten für Platzpools die keine Werte enthalten.</li>
                                            <li>Die einzelnen Werte der Platzpool-Zellen werden für das Mapping auf die Platzpools im System genutzt (Titel des Platzpools).</li>
                                            <li>Alle Platzpools müssen im System bereits angelegt sein.</li>
                                        </ul>
                                    </div>

                                    <br/>
                                    {this.state.missingPlacePools.length > 0 &&
                                    <Alert variant="danger">
                                        Die folgenden Platzpools sind in der Import-Datei angelegt, aber noch nicht im System. Bitte legen Sie diese im System an und importieren Sie die Datei dann erneut:
                                        <br/>

                                        <ul>
                                            {this.state.missingPlacePools.map((field, index) =>
                                                <li>{field}</li>
                                            )}
                                        </ul>
                                    </Alert>
                                    }

                                    {this.state.faultyRows.length > 0 &&
                                    <Alert variant="danger">
                                        {this.state.errorMessage}
                                        <br/>
                                        <ul>
                                            {this.state.faultyRows.map((field, index) =>
                                                <li>
                                                    <div><b>Reason</b>: <span>{field.reason}</span></div>
                                                    <div><b>Block</b>: <span>{field.blockName}</span></div>
                                                    <div><b>Row Label</b>: <span>{field.rowLabel}</span></div>
                                                    <div><b>Seat Label</b>: <span>{field.seatLabel}</span></div>
                                                    <div><b>Lines</b>:</div>
                                                    {field.lines &&
                                                    <ul>
                                                        {field.lines.map((line, index) =>
                                                            <li>
                                                                <div><b>Row</b>: <span>{line.row}</span></div>
                                                                <div><b>Zeilen-ID</b>: <span>{line.id}</span>
                                                                </div>
                                                            </li>
                                                        )}
                                                    </ul>
                                                    }
                                                </li>
                                            )}
                                        </ul>
                                    </Alert>
                                    }
                                    {this.state.validFile !== null && !this.state.validFile && this.state.faultyRows.length === 0 &&
                                    <Alert variant="danger">
                                        {this.state.errorMessage}
                                    </Alert>
                                    }
                                    {this.state.validFile &&
                                    <Alert variant="success">
                                        Valide CSV-Datei erhalten. <br/>
                                        Wenn Sie auf Speichern drücken, wird der Saalplan erzeugt und alle Blockgruppen, Blöcke, Sitze, Bestuhlungstypen und Preiskategorien werden anhand der Daten aus Ihrer Datei erzeugt. <br/>
                                        <b>Dieser Vorgang kann einige Minuten dauern.</b> <br/>
                                        Sollte der Import erfolgreich sein, werden Sie weitergeleitet.
                                    </Alert>
                                    }
                                </Col>
                                }
                            </Row>
                        </div>
                        <Footer>
                            <FeedbackButton to={`/base/venue/${venueId}`}>
                                Abbrechen
                            </FeedbackButton>
                            <FeedbackButton
                                type="submit"
                                busy={submitPending || this.state.importPending}
                                disabled={this.state.validationPending}
                            >
                                Speichern
                            </FeedbackButton>
                        </Footer>
                    </form>
                )
            }}
        </Formik>
    }
}

Form.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    venuePlan: PropTypes.any,
    requestErrors: PropTypes.any
};


const mapStateToProps = (state) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps)(Form);
