import React, { useState, useRef} from 'react';
import { Field, Formik, getIn } from 'formik';
import classnames from "classnames";
import { get } from 'lodash';
import { FORM_SCHEMA } from './schema'
import { Col, Row } from 'react-bootstrap';
import { FormikTextInputGroup } from '../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../common/FeedbackButton';
import Footer from "../layout/Footer";
import {connect} from "react-redux";
import FormPaymentSettings from "./FormPaymentSettings";
import FormMailSettings from "./FormMailSettings";
import FormVoucherSettings from "./FormVoucherSettings";
import FormContact from "../contact/FormContact";
import debounce from "debounce-promise";
import Api from "../../api";
import { LoadingIndicator } from "../common/LoadingIndicator";
import styles from "../form.module.scss";
import {Tab, Tabs} from "@mui/material";
import TabsPanel from "../common/TabsPanel/TabsPanel";
import FieldImage from '../common/FieldImage/FieldImage';
import ListingModal from "../common/modal/ListingModal";
import ErrorFocus from '../common/formik/ErrorFocus';
import { lightenColorPolished } from '../../utils/utils';
import FormInvoiceSettings from "./FormInvoiceSettings";

import {
    headingsPlugin,
    listsPlugin,
    quotePlugin,
    thematicBreakPlugin,
    Separator,
    linkPlugin,
    linkDialogPlugin,
    markdownShortcutPlugin,
    UndoRedo,
    ListsToggle,
    BoldItalicUnderlineToggles,
    toolbarPlugin,
    CreateLink,
    BlockTypeSelect,
    MDXEditor
  } from '@mdxeditor/editor'

const INITIAL_VALUES = {
    slug: '',
    name: '',
    shortName: '',
    domain: '',
    taxId: '',
    googleTagManagerId: '',
    paymentSettings: {
        provider: '',
        payone: {
            environment: 'test',
            merchantId: '',
            apiKey: '',
            apiSecret: '',
            webhookKey: '',
            webhookSecret: '',
            paymentProducts: [],
        },
        mollie: {
            partnerId: '',
            apiKey: '',
            profileId: '',
            paymentMethods: [],
        }
    },
    legalContact: {
        salutation: null,
        firstName: null,
        lastName: null,
        address1: null,
        address2: null,
        companyName: null,
        postalCode: null,
        city: null,
        phone: {
            countryCode: null,
            phoneNumber: null
        },
        mobile: {
            countryCode: null,
            phoneNumber: null
        },
        country: 'DE',
        email: null
    },
    invoiceContact: {
        salutation: null,
        firstName: null,
        lastName: null,
        address1: null,
        address2: null,
        companyName: null,
        postalCode: null,
        city: null,
        country: 'DE',
        phone: {
            countryCode: null,
            phoneNumber: null
        },
        mobile: {
            countryCode: null,
            phoneNumber: null
        },
        email: null
    },
    mailSettings: {
        footer: '',
        description: '',
        server: '',
        port: '',
        username: '',
        password: '',
        sender: '',
        replyTo: null,
        bcc: null
    },
    webSettings: {
        primaryColor: '#1ab394',
        secondaryColor: '#cedfd9',
        logo: null,
        favicon: null,
        hotlineNumber: '',
        hotlineReachability: '',
        imprintUrl: '',
        websiteUrl: '',
        privacyPolicyUrl: '',
        gtcUrl: '',
    },
    voucherSettings: {
        enabled: false,
        apiBaseUri: null,
        apiPublicKey: null,
        apiPrivateKey: null,
        externalTenantId: null,
        externalEventId: null,
        events: []
    },
    invoiceSettings: {
        enabled: false,
        footer: ''
    }
};

const api = new Api();

export const cleanupTenant = (tenant) => {
    if (tenant.paymentSettings && tenant.paymentSettings.provider === '') {
        return {...tenant, paymentSettings: {...tenant.paymentSettings, provider: null}}
    }

    return tenant;
};

const validateTenantSlugDebounced = debounce(slug => api.validateTenantSlug(slug), 500);

const Form = ({ onSubmit, submitPending, tenant, helpTextsVisible }) => {
    const [filteredSlug, setFilteredSlug] = useState(tenant.slug ?? '');
    const [inputSlug, setInputSlug] = useState(tenant.slug ?? '');
    const [slugIsLoading, setSlugIsLoading] = useState(false);
    const [activeFieldName, setActiveFieldName] = useState('');
    const [isShowBatchModal, setIsShowBatchModal] = useState(false);
    const [currentTab, setCurrentTab] = useState('home');

    const closeModal = () => {
        setIsShowBatchModal(false);
    }

    const onHandleShowModal = (e, fieldName) => {
        e.stopPropagation();
        setIsShowBatchModal(true);
        setActiveFieldName(fieldName);
    }

    const validateSlug = async (slug) => {
        if (!slug || inputSlug === slug) return;

        setInputSlug(slug);
        setFilteredSlug(slug);

        setSlugIsLoading(true);
        const result = await validateTenantSlugDebounced(slug);
        setSlugIsLoading(false);

        if (result) {
            if (result.tenantId && result.tenantId !== tenant.id) {
                return 'Der URL-Kürzel wird bereits verwendet'
            }
            if (result.errors && result.errors.length > 0) {
                return result.errors[0];
            }

            if (result.filteredSlug) {
                setFilteredSlug(result.filteredSlug);
            }
        }
    };

    const slug = useRef(null);
    const name = useRef(null);
    const shortName = useRef(null);
    const domain = useRef(null);
    const taxId = useRef(null);

    // legalContact && invoiceContact
    const legalContact = useRef(null);
    const invoiceContact = useRef(null);

    // paymentSettings
    const paymentSettings = useRef(null);

    // mailSettings
    const mailSettings = useRef(null);

    // footer
    const footer = useRef(null);

    // voucherSettings
    const voucherSettings = useRef(null);
    const invoiceSettings = useRef(null);

    // webSettings
    const webSettings = useRef(null);

    const refsData = {
        slug,
        name,
        shortName,
        domain,
        taxId,
        legalContact,
        invoiceContact,
        paymentSettings,
        mailSettings,
        footer,
        voucherSettings,
        webSettings,
        invoiceSettings
    }

    return (
        <Formik
            initialValues={{...INITIAL_VALUES, ...tenant}}
            validationSchema={FORM_SCHEMA}
            onSubmit={onSubmit}
        >
            {formik => {
                const handleChangeDebounced = debounce(value => {
                    formik.setFieldValue('mailSettings.footer', value);
                }, 300);

                const onHandleChooseImage = (item) => {
                    const { id, title, url } = item;
                    formik.setFieldValue(activeFieldName, {
                        title,
                        url,
                        id,
                    });
                    setIsShowBatchModal(false);
                }

                const onHanldleRemove = (e, fieldName) => {
                    e.stopPropagation();
                    formik.setFieldValue(fieldName, null);
                }

                const handlePrimaryColorChange = (event, formik) => {
                    const newPrimaryColor = event.target.value;
                    formik.setFieldValue('webSettings.primaryColor', newPrimaryColor);
                    const lightenedColor = lightenColorPolished(newPrimaryColor);
                    formik.setFieldValue('webSettings.secondaryColor', lightenedColor);
                };

                const baseUrl = `http://${formik.values.domain}/${formik.values.slug}/events/backend`;
                const params = new URLSearchParams({
                    primaryColor: getIn(formik.values, 'webSettings.primaryColor'),
                    secondaryColor: getIn(formik.values, 'webSettings.secondaryColor'),
                    logoUrl: getIn(formik.values, 'webSettings.logo.url'),
                    faviconUrl: getIn(formik.values, 'webSettings.favicon.url'),
                    imprintUrl: getIn(formik.values, 'webSettings.imprintUrl'),
                    gtcUrl: getIn(formik.values, 'webSettings.gtcUrl'),
                    privacyPolicyUrl: getIn(formik.values, 'webSettings.privacyPolicyUrl'),
                    websiteUrl: getIn(formik.values, 'webSettings.websiteUrl'),
                    hotlineNumber: getIn(formik.values, 'webSettings.hotlineNumber'),
                    hotlineReachability: getIn(formik.values, 'webSettings.hotlineReachability')
                });
                const url = `${baseUrl}?${params.toString()}`;

                return (
                    <>
                        <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>

                            <Tabs id="uncontrolled-tab-example" value={currentTab} onChange={(event, newTab) => setCurrentTab(newTab)}>
                                <Tab value="home" label="Stammdaten" />
                                <Tab value="payments" label="Zahlungen" />
                                <Tab value="smtp" label="E-Mail-Versand" />
                                <Tab value="invoice" label="Rechnungs-Einstellungen" />
                                <Tab value="api" label="APIs" />
                                <Tab value="settings" label="Individualisierung" />
                            </Tabs>

                            <div className={styles.tabsContentWrapper}>
                                <TabsPanel value={currentTab} index={'home'} data-event-key='home'>
                                    <div className={styles.formBox}>
                                        <h2 className={styles.formTitle}>Allgemein</h2>
                                        <Row className="mt-3">
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup
                                                    label="Name des Mandanten*"
                                                    name="name"
                                                    testid="name"
                                                    inputRef={refsData.name}
                                                />
                                            </Col>
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup
                                                    label="URL-Kürzel*"
                                                    name="slug"
                                                    testid="slug"
                                                    validate={validateSlug}
                                                    inputRef={refsData.slug}
                                                    helpText="Wird u.A. Bestandteil der Rechnungsnummer"
                                                />
                                                {slugIsLoading && <LoadingIndicator/>}
                                                {filteredSlug !== formik.values.slug && `URL-Kürzel, die in der Datenbank gespeichert werden sollen: "${filteredSlug}"`}

                                            </Col>
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup
                                                    label="Kurzbezeichnung des Mandanten*"
                                                    name="shortName"
                                                    testid="shortName"
                                                    inputRef={refsData.shortName}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup
                                                    label="Domain*"
                                                    name="domain"
                                                    testid="domain"
                                                    inputRef={refsData.domain}
                                                />
                                            </Col>
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup
                                                    label="USt-ID*"
                                                    name="taxId"
                                                    testid="taxId"
                                                    helpTextsVisible={helpTextsVisible}
                                                    inputRef={refsData.taxId}
                                                />
                                            </Col>
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup
                                                    label="Google Tagmanager Id"
                                                    name="googleTagManagerId"
                                                    testid="googleTagManagerId"
                                                    helpTextsVisible={helpTextsVisible}
                                                    helpText="Hier können Sie Ihre Google Tagmanager Id hinterlegen, wenn Sie das User-Verhalten mit Google im Frontend tracken wollen."
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    <div ref={refsData.legalContact}>
                                        <FormContact name="legalContact" title="Vertragsadresse" formik={formik} />
                                    </div>
                                    <div ref={refsData.invoiceContact}>
                                        <FormContact name="invoiceContact" title="Rechnungsadresse" formik={formik} />
                                    </div>
                                </TabsPanel>

                                <TabsPanel value={currentTab} index={'payments'} data-event-key='payments'>
                                    <div ref={refsData.paymentSettings}>
                                        <FormPaymentSettings formik={formik} tenant={tenant} />
                                    </div>
                                </TabsPanel>

                                <TabsPanel value={currentTab} index={'smtp'} data-event-key='smtp'>
                                    <div ref={refsData.footer}>
                                        <div className={styles.formBox}>
                                            <h2 className={styles.formTitle}>Individualisierung Ihrer E-Mail Benachrichtigungen</h2>
                                            <Row className="mt-3">
                                                <Col className="col-md-12 col-lg-6">
                                                    <span className={classnames(styles.MDXEditorLabel, {[styles.MDXEditorLabelError] : get(formik.touched, 'mailSettings.footer') || get(formik.errors, 'mailSettings.footer') })}>E-Mail Signatur:*</span>
                                                    <MDXEditor
                                                        value={get(formik.values, 'mailSettings.footer', '')}
                                                        onChange={handleChangeDebounced}
                                                        markdown={get(formik.values, 'mailSettings.footer', '')}
                                                        className={classnames(styles.MDXEditorWrapWrap, {[styles.MDXEditorWrapError] : get(formik.touched, 'mailSettings.footer') || get(formik.errors, 'mailSettings.footer') })}
                                                        suppressHtmlProcessing={false}
                                                        contentEditableClassName={styles.MDXEditorContent}
                                                        plugins={[
                                                            toolbarPlugin({
                                                                toolbarContents: () => (
                                                                <>
                                                                    <UndoRedo />
                                                                    <Separator />
                                                                    <BoldItalicUnderlineToggles />
                                                                    <Separator />
                                                                    <ListsToggle />
                                                                    <Separator />
                                                                    <BlockTypeSelect />
                                                                    <Separator />
                                                                    <CreateLink />

                                                                </>
                                                                )
                                                            }),
                                                            listsPlugin(),
                                                            headingsPlugin(),
                                                            linkPlugin(),
                                                            linkDialogPlugin(),
                                                            quotePlugin(),
                                                            thematicBreakPlugin(),
                                                            markdownShortcutPlugin(),
                                                        ]}
                                                    />
                                                    {get(formik.touched, 'mailSettings.footer') || get(formik.errors, 'mailSettings.footer') ? (
                                                        <div className="text-danger">{get(formik.errors, 'mailSettings.footer', '')}</div>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div ref={refsData.mailSettings}>
                                        <FormMailSettings formik={formik} tenant={tenant} />
                                    </div>
                                </TabsPanel>
                                <TabsPanel value={currentTab} index={'invoice'} data-event-key='invoice'>
                                    <div ref={refsData.invoiceSettings}>
                                        <FormInvoiceSettings formik={formik} tenant={tenant}/>
                                    </div>
                                </TabsPanel>
                                <TabsPanel value={currentTab} index={'api'} data-event-key='api'>
                                    <div ref={refsData.voucherSettings}>
                                        <FormVoucherSettings formik={formik} tenant={tenant} />
                                    </div>
                                </TabsPanel>

                                <TabsPanel value={currentTab} index={'settings'} data-event-key='settings'>
                                    <div className={styles.formBox} ref={refsData.webSettings}>
                                        <h2 className={styles.formTitle}>Individualisierung Ihrer Farbgebung</h2>
                                        <Row className="mt-3">
                                            <Col className="col-md-4">
                                                <div className='MuiFormWrapper'>
                                                    <label className='MuiInputLabel'>Primärfarbe: (Buttons, grafische Hauptelemente)</label>
                                                    <Field
                                                        type="color"
                                                        name="webSettings.primaryColor"
                                                        className="form-control"
                                                        onChange={(event) => handlePrimaryColorChange(event, formik)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-md-4">
                                                <FieldImage
                                                    values={getIn(formik.values, 'webSettings.logo')}
                                                    fieldName='webSettings.logo'
                                                    label="Ihr Logo auswählen (Header Links)"
                                                    onHandleShowModal={onHandleShowModal}
                                                    onHanldleRemove={onHanldleRemove}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col className="col-md-4">
                                                <div className='MuiFormWrapper'>
                                                    <label className='MuiInputLabel'>Sekundärfarbe: (Hintergrund, grafische Nebenelemente)</label>
                                                    <Field
                                                        type="color"
                                                        name="webSettings.secondaryColor"
                                                        className="form-control"
                                                        disabled
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-md-4">
                                                <FieldImage
                                                    values={getIn(formik.values, 'webSettings.favicon')}
                                                    fieldName='webSettings.favicon'
                                                    label="Ihr Favicon auswählen (Bild im Browsertab)"
                                                    onHandleShowModal={onHandleShowModal}
                                                    onHanldleRemove={onHanldleRemove}
                                                />
                                            </Col>
                                        </Row>
                                        {formik.values.slug && formik.values.domain && (
                                            <Row className="mt-3">
                                                <Col className="col-md-4">
                                                    <a href={url}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <FeedbackButton variant="outlined">
                                                            Seitenvorschau
                                                        </FeedbackButton>
                                                    </a>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                    <div className={styles.formBox}>
                                        <h2 className={styles.formTitle}>Verlinkungen</h2>
                                        <Row className="mt-5">
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup
                                                    label="URL zum Impressum*"
                                                    name="webSettings.imprintUrl"
                                                    testid="imprintUrl"
                                                    helpText="Wird im Kaufprozess angezeigt."
                                                    helpTextsVisible={helpTextsVisible}
                                                />
                                            </Col>
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup
                                                    label="URL zu den AGBs*"
                                                    name="webSettings.gtcUrl"
                                                    placeholder="https://"
                                                    helpText="Wird im Kaufprozess angezeigt."
                                                />
                                            </Col>
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup
                                                    label="URL zu den Datenschutzbestimmungen*"
                                                    name="webSettings.privacyPolicyUrl"
                                                    placeholder="https://"
                                                    helpText="Wird im Kaufprozess angezeigt."
                                                />
                                            </Col>
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup
                                                    label="Hotline Nummer"
                                                    name="webSettings.hotlineNumber"
                                                    helpText="Wird im Kaufprozess angezeigt."
                                                />
                                            </Col>
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup
                                                    label="Hotline Erreichbarkeit"
                                                    name="webSettings.hotlineReachability"
                                                    helpText="Wird im Kaufprozess angezeigt."
                                                />
                                            </Col>
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup
                                                    label="URL zur Website*"
                                                    name="webSettings.websiteUrl"
                                                    helpText="Wird im Kaufprozess angezeigt."
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </TabsPanel>
                            </div>
                            <ErrorFocus setCurrentTab={setCurrentTab} refs={refsData}/>
                            <Footer>
                                <FeedbackButton to={`/base/tenant`}>
                                    Abbrechen
                                </FeedbackButton>
                                <FeedbackButton
                                    type="submit"
                                    busy={submitPending}
                                >
                                    Speichern
                                </FeedbackButton>
                            </Footer>
                        </form>
                        <ListingModal
                            show={isShowBatchModal}
                            title="Bild wählen"
                            initialValues={INITIAL_VALUES}
                            validationSchema={FORM_SCHEMA}
                            onCancel={closeModal}
                            onHandleChooseImage={onHandleChooseImage}
                        />
                    </>
                )
            }}
        </Formik>
    )
}

Form.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    tenant: PropTypes.any,
    requestErrors: PropTypes.any
};


const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps)(Form);
