import React, { useEffect } from 'react';
import styles from './menu.module.scss';
import classnames from 'classnames';
import { Link, useLocation } from "react-router-dom";
import {AuthConsumer} from "../auth/AuthProvider";
import logo from '../../assets/images/logo.svg';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {useSelector} from "react-redux";

/**
 * Create a new navbar instance.
 *
 * @param     {Object}      props
 * @property  {String|null} className CSS Selector classes.
 * @property  {JSX}         children
 * @property  {Boolean}     isOpen 	  The current state of the menu.
 * @property  {Function}    toggle 	  A callback to toggle the menu state.
 * @return    {JSX}
 */
export default ({
    home,
    className,
    children,
    innerRef,
    menuWrapRef,
    isOpen,
    onClick = () => {}
  }) => {
    const location = useLocation();
    const path = location.pathname;

    const getMenuClass = () => {
      if (path.includes('/base')) {
        return styles.baseWrap;
      } else if (path.includes('/event-management')) {
        return styles.eventManagementWrap;
      } else if (path.includes('/sales')) {
        return styles.salesWrap;
      }
      return styles.defaultWrap;
    };

    const currentTenant = useSelector(state => state.currentTenant);

    useEffect(() => {
      if (path.includes('/base')) {
        document.body.style.backgroundColor = '#ECE6EE';
      } else if (path.includes('/event-management')) {
        document.body.style.backgroundColor = '#F1F4F6';
      } else if (path.includes('/sales')) {
        document.body.style.backgroundColor = '#FBF2E5';
      } else {
        document.body.style.backgroundColor = '#F1F4F6';
      }
      return () => {
        document.body.style.backgroundColor = '';
      };
    }, [path]);

    return (
      <div
        ref={innerRef}
        className={classnames(className, styles.Menu, getMenuClass(), isOpen ? styles.MenuOpen : '', 'side-menu')}
        onClick={onClick}
      >
      { /* Inner wrap needs a custom scrollbar */}
      <Link to={home || '/'} className={styles.logo}>
        <img className={styles.NavbarBrandLogo} src={logo} alt="Leipziger Messe" />
        {currentTenant.name}
      </Link>
      <div className={styles.InnerWrap} ref={menuWrapRef}>{children}</div>
      <div className={styles.userLogout}>
        <AccountCircleOutlinedIcon className={styles.userLogoutIcon}/>
        <AuthConsumer>
            {({ getUserFullname }) => {
              return <span className="mr-2">{getUserFullname()}</span>
            }}
          </AuthConsumer>
      </div>
    </div>
)};
