import React, { useState } from 'react';
import {Card, Col, Row} from "react-bootstrap";
import {FormattedDate} from "react-intl";
import Api from "../../api";
import {Link} from "react-router-dom";
import Euro from "../common/numberFormat/Euro";
import Footer from '../layout/Footer';
import FeedbackButton from '../common/FeedbackButton';
import ConnectedListView from "../common/listView/ConnectedListView";
import PaymentDetails from "./PaymentDetails";
import {
    FormControl,
    InputLabel,
    Select as MuiSelect,
    MenuItem
} from '@mui/material';
import {
    BATCH_ACTION_CANCEL_PAYMENT,
    BATCH_ACTION_RESOLVE_SERVICE_CASE_CANCELLATION_MANUAL
} from "../common/listView/ListViewBatchActions";
import { isEmpty, get } from 'lodash';

const api = new Api();

const SERVICE_EMAILS_OPTIONS = [
    { id: 'thank_you_email', name: 'Bestellbestätigung' },
    { id: 'cancellation_email', name: 'Aktualisierungsbenachrichtigung' },
];

const OrderDetails = ({order}) => {
    const [selectedEmailType, setSelectedEmailType] = useState('');
    const [messageResentEmail, setMessageResentEmail] = useState({
        error: false,
        message: ''
    });
    
    const SALUTATION_OPTIONS = {
        m: "Herr",
        f: "Frau",
        d: "Divers",
    };

    const Address = ({contact}) => {
        if (!contact) {
            return <div className={"form-group mt-4"}>Missing contact information</div>;
        }
        return <>
            <div className={"form-group mt-4"}>
                <span className={"mt-2 mr-4"}>Anrede:</span>
                {SALUTATION_OPTIONS[contact.salutation]}
            </div>
            <div className={"form-group mt-4"}>
                <span className={"mt-2 mr-4"}>Name:</span>
                {(contact.firstName || contact.lastName) ?
                    <span className={"mt-2"}>{[contact.firstName, contact.lastName].join(" ")}</span> : null}
            </div>
            <div className={"form-group mt-4"}>
                <div className={"form-group"}>
                    <span className={"mt-2 mr-4"}>E-Mail:</span>
                    <span className={"mt-2"}>{contact.email}</span>
                </div>
                <div className={"form-group"}>
                    <span className={"mt-2 mr-4"}>Mobil:</span>
                    <span
                        className={"mt-2"}>{get(contact, 'phone.countryCode')}{get(contact, 'phone.phoneNumber')}</span>
                </div>
                <div className={"form-group"}>
                    <span className={"mt-2 mr-4"}>Straße & Hausnummer:</span>
                    <span className={"mt-2"}>{contact.address1}</span>
                </div>
                <div className={"form-group"}>
                    <span className={"mt-2 mr-4"}>Adresszusatz:</span>
                    <span className={"mt-2"}>{contact.address2}</span>
                </div>
                <div className={"form-group"}>
                    <span className={"mt-2 mr-4"}>PLZ:</span>
                    <span className={"mt-2"}>{contact.postalCode}</span>
                </div>
                <div className={"form-group"}>
                    <span className={"mt-2 mr-4"}>Stadt:</span>
                    <span className={"mt-2"}>{contact.city}</span>
                </div>
                <div className={"form-group"}>
                    <span className={"mt-2 mr-4"}>Land:</span>
                    <span className={"mt-2"}>{contact.country}</span>
                </div>
            </div>
        </>
    };

    const handleExport = () => {
        api.getBIData(order.id)
            .then(data => {
                const jsonData = JSON.stringify(data, null, 2);
                const blob = new Blob([jsonData], {type: 'text/plain'});
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'exported_data.txt');
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
                document.body.removeChild(link);
            })
            .catch(error => {
                console.error('Error fetching BI Data:', error);
            });
    };

    const paymentType = (type) => {
        switch (type) {
            case 'fake':
                return 'fake';
            case 'payone':
                return order?.payments[0]?.paymentProductIdLabel;
            default:
                return type;
        }
    };

    const handleSendOutServiceEmails = () => {
        api.getOrderResendEmail(order.id, selectedEmailType).then((data) => {
            if (selectedEmailType === 'thank_you_email' && data.status === 204) {
                setMessageResentEmail({ error: false, message: 'Bestellbestätigung wurde erneut versendet.'})
            } else if (selectedEmailType === 'cancellation_email' && data.status === 204) {
                setMessageResentEmail({ error: false, message: 'E-Mail wurde erneut versendet.'})
            }
        }).catch((error) => {
            setMessageResentEmail({ error: true, message: error.message});
        });
    };

    return <>
        <Row>
            <Col className="mt-4 col-12">
                <Card className="mt-4 mb-4">
                    <Card.Body>
                        <h2 className="font-weight-bold pb-3 mb-3">Bestelldetails</h2>
                        {order.events.map(({
                            id,
                            title,
                            type,
                            venue,
                            startDate,
                            grossAmount,
                            cancelledAmount,
                            finalAmount
                        }) => {
                            return (
                                <React.Fragment key={"data" + id}>
                                    <Row key={"head" + id} className="mt-3">
                                        <Col className="col-md-6">
                                            {type === 'venueEvent' &&
                                                <Link to={`/event-management/event/${id}`}>
                                                    {title.de}
                                                </Link>
                                            }

                                            {type === 'metaEvent' &&
                                                <Link to={`/event-management/meta-event/${id}`}>
                                                    {title.de}
                                                </Link>
                                            }
                                            &nbsp;
                                            (<FormattedDate value={startDate} year="numeric" month="2-digit"
                                                            day="2-digit" hour="2-digit" minute="2-digit"/>)
                                            &nbsp;
                                            <Link to={`/base/venue/${venue.id}`}>
                                                {venue.name}
                                            </Link>
                                        </Col>
                                    </Row>
                                    <ConnectedListView
                                        endpoint={`listingView/cartItem?orderId=${order.id}&eventId=${id}`}
                                        isShowSelectionWithBatchActions={true}
                                        allowedActions={[BATCH_ACTION_CANCEL_PAYMENT]}
                                    >
                                    </ConnectedListView>
                                    <Row key={"final-summary"} className="mt-3">
                                        <Col className="col-md-10"></Col>
                                        <Col className="col-md-1 font-weight-bold">
                                            Summe
                                        </Col>
                                        <Col className="col-md-1">
                                            <Euro centAmount={finalAmount}/>
                                        </Col>
                                    </Row>
                                    {Math.abs(cancelledAmount) > 0 && <>
                                        <Row key={"cancelled-summary"} className="mt-3">
                                            <Col className="col-md-10"></Col>
                                            <Col className="col-md-1 font-weight-bold">
                                                Stornos
                                            </Col>
                                            <Col className="col-md-1">
                                                <Euro centAmount={Math.abs(cancelledAmount) ?? 0}/>
                                            </Col>
                                        </Row>
                                        <Row key={"gross-summary"} className="mt-3">
                                            <Col className="col-md-10"></Col>
                                            <Col className="col-md-2 font-weight-bold">
                                                (Ursprünglicher Bestellwert: <Euro centAmount={grossAmount ?? 0}/>)
                                            </Col>
                                        </Row>
                                    </>}
                                </React.Fragment>
                            )
                        })}
                        <Row key={"summary"} className="mt-3">
                            <Col className="col-md-2">
                                <FormControl fullWidth variant="outlined" style={{ height: 'inherit' }}>
                                    <InputLabel>E-Mail erneut versenden</InputLabel>
                                    <MuiSelect
                                        label={'E-Mail erneut versenden'}
                                        displayEmpty={true}
                                        value={selectedEmailType}
                                        renderValue={(selected) => {
                                            if (isEmpty(selected)) {
                                                return <em>Bitte wählen...</em>;
                                            }
                                            const selectedOption = !isEmpty(SERVICE_EMAILS_OPTIONS) && SERVICE_EMAILS_OPTIONS.find(option => option.id === selected);
                                            if (!selectedOption) return '';
                                            return selectedOption ? selectedOption.name : selected;
                                        }}
                                        onChange={event => setSelectedEmailType(event.target.value)}
                                    >
                                        {!isEmpty(SERVICE_EMAILS_OPTIONS) && SERVICE_EMAILS_OPTIONS.map(({ name, id }) => {
                                            return (
                                                <MenuItem key={id} value={id}>
                                                    {name}
                                                </MenuItem>
                                            )
                                        })}
                                    </MuiSelect>
                                </FormControl>
                                <FeedbackButton onClick={handleSendOutServiceEmails}>
                                    Erneut senden
                                </FeedbackButton>
                            </Col>
                            <Col className='col-md-2 align-self-center' style={{ paddingBottom: '30px' }}>
                                {messageResentEmail.message && <span style={{ color: messageResentEmail.error ? '#B3261E' : 'rgba(75, 169, 139, 1)'}}>{messageResentEmail.message}</span>}
                            </Col>
                            <Col className="col-md-6"></Col>
                            <Col className="col-md-1 font-weight-bold">
                                Gesamtsumme der Bestellung
                            </Col>
                            <Col className="col-md-1">
                                <Euro centAmount={order.grossAmount}/>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        {order.cancellations.map((cancellation, index) => {
            return (
                <Row key={"cancellation" + index}>
                    <Col className="mt-4 col-12">
                        <Card className="mt-4 mb-4">
                            <Card.Body>
                                <h2 className="font-weight-bold pb-3 mb-3">
                                    <span>Stornoprozess vom </span>
                                    <FormattedDate value={cancellation.createdAt} year="numeric" month="2-digit"
                                                   day="2-digit" hour="2-digit" minute="2-digit"/>
                                </h2>
                                <ConnectedListView
                                    endpoint={`listingView/orderCancellationCartItem?orderCancellationId=${cancellation.id}`}
                                    isShowSelectionWithBatchActions={true}
                                    allowedActions={[BATCH_ACTION_RESOLVE_SERVICE_CASE_CANCELLATION_MANUAL]}
                                >
                                </ConnectedListView>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )
        })}
        <Row>
            <Col className="mt-4 col-sm-12 col-lg-4">
                <Card className="mt-4 mb-4">
                    <Card.Body>
                        <h2>Auftragsdaten</h2>
                        <div className={"form-group"}>
                            <span className={"mt-2 mr-4"}>Gesamtstatus:</span>
                            <span className={"mt-2"}>
                                    {order.statusChanges.map((statusChange, index) => <Row key={index}>
                                        <Col>
                                            <FormattedDate value={statusChange.occurred} year="numeric" month="2-digit"
                                                           day="2-digit" hour="2-digit" minute="2-digit"
                                                           second="2-digit"/>
                                        </Col>
                                        <Col>
                                            {statusChange.status}
                                        </Col>
                                    </Row>)}
                                </span>
                        </div>

                        <div className={"form-group"}>
                            <span className={"mt-2 mr-4"}>Auftragsnummer:</span>
                            <span className={"mt-2"}>{order?.payments[0]?.id}</span>
                        </div>
                        <div className={"form-group"}>
                            <span className={"mt-2 mr-4"}>Auftragsdatum:</span>
                            <span className={"mt-2"}>
                                    <FormattedDate value={order.createdAt} year="numeric" month="2-digit" day="2-digit"
                                                   hour="2-digit" minute="2-digit"/>
                                </span>
                        </div>
                        {order.expiresAt && <div className={"form-group"}>
                            <span className={"mt-2 mr-4"}>Die Bestellung endet am:</span>
                            <span className={"mt-2"}>
                                    <FormattedDate value={order.expiresAt} year="numeric" month="2-digit" day="2-digit"
                                                   hour="2-digit" minute="2-digit"/>
                                </span>
                        </div>}
                        <div className={"form-group"}>
                            <span className={"mt-2 mr-4"}>letzte Änderung:</span>
                            <span className={"mt-2"}><FormattedDate
                                value={order?.payments[0]?.statusChanges[0]?.occurred} year="numeric" month="2-digit"
                                day="2-digit" hour="2-digit" minute="2-digit"/></span>
                        </div>
                        <div className={"form-group"}>
                            <span className={"mt-2 mr-4"}>E-Mail-Adresse:</span>
                            <span className={"mt-2"}>{order?.legalContact?.email}</span>
                        </div>
                        <div className={"form-group"}>
                            <span className={"mt-2 mr-4"}>Zahlart:</span>
                            <span className={"mt-2"}>{paymentType(order?.payments[0]?.type)}</span>
                        </div>
                        <div className={"form-group"}>
                            <span className={"mt-2 mr-4"}>Besteller:</span>
                            <span className={"mt-2"}>
                                {order.buyer.anonymous && "Anonymous"}
                                {!order.buyer.anonymous && !order.buyer.backendUserId && order.buyer.id}
                                {!order.buyer.anonymous && order.buyer.backendUserId &&
                                    <Link to={`/base/backend-user/${order.buyer.backendUserId}`}>
                                        {order.buyer.firstName} {order.buyer.lastName}
                                    </Link>}
                            </span>
                        </div>
                        <div className={"form-group"}>
                            <span className={"mt-2 mr-4"}>Verkaufskanal:</span>
                            <span className={"mt-2"}>{order.salesChannelName}</span>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="mt-4 col-sm-12 col-lg-4">
                <Card className="mt-4 mb-4">
                    <Card.Body>
                        <h2>Käuferadresse</h2>
                        <Address contact={order.legalContact}/>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="mt-4 col-sm-12 col-lg-4">
                <Card className="mt-4 mb-4">
                    <Card.Body>
                        <h2>Rechnungsadresse</h2>
                        <Address contact={order.invoiceContact}/>
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        {order.payments && order.payments.map(payment => (
            <Card className="mt-4 mb-4" key={payment.id}>
                <Card.Body>
                    <h2 className="font-weight-bold pb-3 mb-3">Payment {payment.id}</h2>
                    <Row className="mt-3">
                        <Col className="pt-2">
                            <PaymentDetails
                                payment={payment}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        ))}
        <Footer>
            <FeedbackButton to={`/sales/order`}>
                Abbrechen
            </FeedbackButton>
            <FeedbackButton onClick={handleExport}>
                Export BI Data
            </FeedbackButton>
        </Footer>
    </>

}

export default OrderDetails;
